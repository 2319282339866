@import '~antd/dist/antd.less';

@import url("./assets/css/general.css");
@import url("./assets/css/qtyInput.css");
@import url("./assets/css/app.scss");
@import url("./assets/css/carousel.css");
@import url("./assets/css/carousel.css");
@import url("@/containers/views/ContactAssociate/style.less");
@import url("@/components/SelectWarehouseModal/WarehouseList.less");
@import url("@/components/General/Category.less");



.item-card {
  // border: @border-radius-base solid #f8f8f8;

  :hover {
    cursor: pointer;
    // background-color: @primary-2;
    border-radius: @border-radius-base;
  }
}
@primary-color: #E96106;@badge-color: #E96106;@layout-header-background: #212121;@border-radius-base: 8px;@checkbox-border-radius: 4px;