/* SMALL */
.qty-small-label {
  width: 100px;
  height: 20px;
  align-items: inherit;
  text-align: center;
  font-size: 11px;
}

.qty-small-container {
  width: 100px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(230 235 241);
}

.qty-small-container .qty-button-container {
  display: flex;
  justify-content: center;
  width: 30%;
  cursor: pointer;
}

.qty-small-container .qty-value {
  display: flex;
  justify-content: center;
  width: 40%;
  user-select: none;
}

/* LARGE */
.qty-large-label {
  width: 150px;
  height: 20px;
  align-items: inherit;
  text-align: center;
  font-size: 13px;
}

.qty-large-container {
  width: 150px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(230 235 241);
}

.qty-large-container .qty-button-container {
  display: flex;
  justify-content: center;
  width: 30%;
  cursor: pointer;
}

.qty-large-container .qty-button {
  color: #2c2c2c;
}

.qty-large-container .qty-value {
  display: flex;
  justify-content: center;
  width: 40%;
  user-select: none;
}
@primary-color: #E96106;@badge-color: #E96106;@layout-header-background: #212121;@border-radius-base: 8px;@checkbox-border-radius: 4px;